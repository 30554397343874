<template>
  <div class="mission-page">
    <div class="mission-banner"></div>
    <div class="container">
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item"><a href="/missionlist">信息联动</a></li>
            <li class="breadcrumb-item active"
                aria-current="page">
              信息联动详情
            </li>
          </ol>
        </nav>
        <div class="article-title">
          {{ info.title }}
        </div>
        <div class="article-info">
          <div class="article-info-left">
            <div class="article-time">{{ info.created_at }}</div>
            <div class="article-time">文章发布者：{{ info.recommend_by }}</div>
            <div class="article-time">文章来源：{{ info.source }}</div>
          </div>
          <div class="article-des"
               @click="toFavourite">
            <span><i class="iconfont icon-flowrate"></i> {{ info.flowrate }}</span>
            <span><i :class="[
                  'iconfont',
                  info.is_favourite == 1
                    ? 'icon-favourite_selected'
                    : 'icon-favourite_normal',
                ]"></i></span>
          </div>
        </div>
        <div class="rich-text">
          <div id="vidro-content"
               v-if="info.vid.length > 0"
               v-show="info.vid"></div>
          <p v-html="info.content" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { missionDetail, missionFavourite } from "@/network/API.js";
import { Base64 } from "js-base64";
import "../../utils/txplayer";
export default {
  data() {
    return {
      info: {},
      player: null,
    };
  },
  async created() {
    if (this.$route.params.id) {
      let data = await missionDetail(this.$route.params.id);
      data.content = Base64.decode(data.content);
      this.info = data;
      if (this.info.vid) {
        this.playerInit(this.info.vid);
      }
    }
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
  methods: {
    playerInit(vid) {
      //调用且传腾讯视频的id即可
      this.player = new Txplayer({
        containerId: "vidro-content",
        vid: vid,
        width: "100%",
        height: screen.availHeight / 3,
        autoplay: true,
      });
    },
    async toFavourite() {
      let res = await missionFavourite({
        id: this.info.news_id,
      });
      if (res.is_favourite == 1) {
        this.$toast("收藏成功")
      } else {
        this.$toast("取消收藏")
      }
      this.info.is_favourite = res.is_favourite
    },
  },
};
</script>
<style>
.mission-banner {
  width: 100%;
  height: 300px;
  background: url(../../assets/safety/banner-mission.jpg) top center no-repeat;
  margin-bottom: -32px;
}

.news-title {
  font-size: 24px;
  font-weight: 700;
}
nav {
  position: relative;
}
.search-box {
  top: 10px;
}
</style>
